import React, { useState } from 'react'
import { FiCheck, FiSave, FiSearch, FiX } from 'react-icons/fi'
import Menu from './Menu'
import Button from './UI/Button'
import Title from './UI/Title'
import { useTranslation } from 'react-i18next'

export default function Catalog({ showByDefault, catalog, format, selectTemplate }) {
  const { t } = useTranslation()
  const templates = catalog && catalog.templates.filter(t => t.config === format.id).sort((a, b) => a.name.localeCompare(b.name))
  const [open, setOpen] = useState(showByDefault && templates)
  return (
    <>
      <div className="absolute top-0 left-0 mt-12 lg:mt-16 flex items-center justify-center w-screen overflow-hidden h-16 lg:h-28 flex-shrink-0">
        <div className="flex justify-center h-32 -mt-4 bg-blue-dark shadow-md opacity-75">
          <img className="opacity-80" src="/templates.jpg" />
          <img className="opacity-80" src="/templates.jpg" />
        </div>
        <div className="absolute w-full left-0 flex justify-center items-center">
          <Button Icon={FiSearch} className="shadow-lg" onClick={() => setOpen(true)}>
            {t('catalog.browseTemplates')}
          </Button>
        </div>
      </div>
      {open && catalog &&
        <div className="fixed top-0 left-0 w-screen h-screen z-40 bg-white bg-opacity-90 p-2 lg:p-4 overflow-y-auto">
          <Menu title={t('catalog.creationModels')}>
            <Button Icon={FiX} variant="secondary" onClick={() => setOpen(false)}>{t('global.close')}</Button>
          </Menu>
          <div className="flex justify-center items-center flex-wrap pt-12 lg:pt-16">
            {templates.map(template =>
              <div
                onClick={() => { selectTemplate(template); setOpen(false) }}
                className="relative flex flex-col justify-center items-center m-2 lg:m-4 transform scale-100 hover:scale-105 transition-transform duration-200 ease-in-out cursor-pointer">
                <img src={template.render?.url} className="h-52 lg:h-80 bg-gray-100 bg-opacity-50" style={{ filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))' }} />
                <div className="text-sm font-medium text-gray-600 mt-2">{template.name}</div>
              </div>
            )}
          </div>
        </div>
      }
    </>
  )
}