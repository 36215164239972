import React, { useEffect, useState } from 'react'
import { downloadFile, exportSvg, parseSvg } from '../utils'
import { findCatalog, findProject, updateProject } from '../api'
import Drawer from '../components/Drawer'
import Loader from '../components/UI/Loader'
import Controls from '../components/Controls'
import Menu from '../components/Menu'
import Catalog from '../components/Catalog'
import { FiCheck, FiDownload, FiX } from 'react-icons/fi'
import Button from '../components/UI/Button'
import { useThrottle } from '@react-hook/throttle'
import Error from '../components/UI/Error'
import '../i18n'
import { useTranslation } from 'react-i18next'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const isIframe = () => (typeof window !== `undefined`) ? window.self !== window.top : false
const match = (location().pathname.match(/project\/(\d+)_(.+)/) || [])
const projectId = match[1]
const token = match[2]

// https://christoshrousis.com/05-how-to-communicate-with-an-iframe-using-react-hooks/

export default function Project() {
  const { t } = useTranslation()
  const [project, setProject] = useState()
  const [local, setLocal] = useThrottle({ images: [], textes: [], background: {} }, 30, true)
  const [config, setConfig] = useState({})
  const [catalog, setCatalog] = useState()
  const [svgRef, setSvgRef] = useState()

  useEffect(() => {
    findProject(projectId, token).then(async p => {
      setProject(p)
      if (p.svg)
        setLocal(parseSvg(p.svg))
      if (p.catalog)
        setCatalog(await findCatalog(p.catalog.id))
    })
  }, [])
  useEffect(() => {
    if (project?.config?.svg) setConfig(parseSvg(project.config.svg))
  }, [project])
  const update = data => setLocal({ ...local, ...data })
  const save = async () => {
    try {
      // const render = await exportSvg(svgRef.current, 'png')
      const saved = await updateProject(projectId, token, { svg: svgRef.current.outerHTML })
      window.top.postMessage({ ...saved, event: 'saved' }, '*')
    } catch (error) {
      console.log(error)
    }
  }
  const download = async () => {
    const render = await exportSvg(svgRef.current, 'png')
    downloadFile(render, `export-wizito.png`)
  }
  const downloadBase = async () => {
    const render = await exportSvg(project.config.svg)
    downloadFile(render, `export-wizito.png`, 'png')
  }
  if (project === undefined) return <Loader />
  if (project.error) return <Error>{t('global.resourceNotFound')}</Error>
  return (
    <>
      <Menu title={t('project.graphicCreation')}>
        <Button Icon={FiCheck} onClick={save} className="mx-1">{t('global.save')}</Button>
        <Button Icon={FiDownload} onClick={download} variant="icon" className="mx-1" label={t('global.download')} />
        {isIframe() &&
          <Button Icon={FiX} variant="secondary" onClick={() => window.top.postMessage({ event: 'exit' }, '*')} className="mx-1">{t('global.leave')}</Button>
        }
      </Menu>
      <Catalog showByDefault={!project.render} catalog={catalog} format={project.config} selectTemplate={t => setLocal(parseSvg(t.svg))} />
      <div className="h-screen pt-28 lg:pt-44 flex flex-col lg:flex-row justify-start lg:justify-center w-full">
        <div className="flex justify-center p-2 flex-shrink lg:p-10 max-h-3/4 lg:max-h-full lg:h-full w-full lg:w-max">
          <Drawer config={config} local={local} setRef={ref => setSvgRef(ref)} className="rounded" />
        </div>
        <div className="overflow-x-scroll lg:overflow-x-auto lg:overflow-y-scroll w-screen lg:w-auto flex-shrink-0 flex flex-row lg:h-full lg:block">
          <Controls local={local} update={update} size={config.size} downloadBase={downloadBase} />
        </div>
      </div>
    </>
  )
}

